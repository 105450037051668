/* (C) Copyright 2020 MediaWink, LLC */

body {
  margin: 0;
  padding: 0;

/*  background-color: #282c34; */
  display: flex;
  flex-direction: column;
/*  justify-content: center; */
  /* font-size: calc(4px + 2vmin); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Select dropDowns */
span {
  font-family: sans-serif;
}

/* unvisited link */
a:link {
/*  color: white; */
/*  color: black; */
/*  border: 2px solid #4CAF50; */
/*  padding: 4px 4px; */
  text-align: left;
/*  text-decoration: none; */
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
}

/* visited link */
a:visited {
  color: green;
  background-color: white;
}

/* mouse over link */
a:hover {
  color: blue;
  background-color: grey; }

/* selected link */
a:active {
/*  color: blue; */
  background-color: white;
}

/* Split Pane: https://github.com/tomkp/react-split-pane */
.Resizer {
  position: relative;
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 15px solid rgba(255, 255, 255, 0);
  border-bottom: 15px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
.Resizer.horizontal:hover {
  border-top: 15px solid rgba(0, 0, 0, 0.5);
  border-bottom: 15px solid rgba(0, 0, 0, 0.5);
}
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 15px solid rgba(255, 255, 255, 0);
  border-right: 15px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
.Resizer.vertical:hover {
  border-left: 15px solid rgba(0, 0, 0, 0.5);
  border-right: 15px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
cursor: not-allowed;
}
.Resizer.disabled:hover {
border-color: transparent;
}

.grid th, td {
  border: 1px solid lightgrey;
}
